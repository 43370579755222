<template>
  <vue-final-modal v-model="showModal" name="email-login-modal">
    <div :class="$style.loginFormContainer">
      <div>
        <header>
          <h2>Login</h2>
        </header>
        <main>
          <template v-if="sendEmailStatusSuccess">
            <p>Login email sent</p>
          </template>
          <template v-else>
            <form>
              <div :class="$style.formRow">
                <BaseLabel for="email">Email</BaseLabel>
                <BaseInput v-model="email" type="email" name="email" />
              </div>

              <div :class="$style.formRow">
                <BaseLabel for="password">Password</BaseLabel>
                <BaseInput v-model="password" type="password" name="password" />
              </div>
              <div :class="$style.actionsRow">
                <BaseButton
                  variant="primary"
                  :disabled="sendEmailStatusPending"
                  @click.prevent="sendLoginLink"
                  >Email Login Link</BaseButton
                >
                <BaseButton>Login</BaseButton>
              </div>
            </form>
          </template>
        </main>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import { sendEmailLoginLink } from "@/api/authApi";
import { apiStatusComputedFactory } from "@/api/helpers/apiStatusComputedFactory";
import { IDLE, PENDING, SUCCESS, ERROR } from "@/constants/apiStatus";
import { withAsync } from "@/helpers";
export default {
  data() {
    return {
      showModal: false,
      email: "bryson@musictechworks.com",
      password: "",
      sendEmailStatus: IDLE,
    };
  },
  computed: {
    ...apiStatusComputedFactory("sendEmailStatus"),
  },

  methods: {
    async sendLoginLink() {
      if (!this.email) {
        alert("Please enter email");
        return;
      }
      this.sendEmailStatus = PENDING;

      const { error } = await withAsync(() => sendEmailLoginLink(this.email));
      if (error) {
        console.error(error);
        this.sendEmailStatus = ERROR;
        return;
      }
      this.sendEmailStatus = SUCCESS;
    },
  },
};
</script>

<style lang="scss" module>
.loginFormContainer {
  background: white;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formRow {
}

.actionsRow {
}
</style>